<template>
  <el-form-item>
    <el-autocomplete
        class="inline-input"
        :class="[(focused || !!inputVal) ? 'focused' : null]"
        v-model="inputVal"
        :fetch-suggestions="querySearch || getOptions"
        placeholder=""
        @select="handleSelect"
        @focus="listenFocus"
        @blur="listenBlur"
    />
    <label :for="name" class="no-block">
      {{ placeholder }}
    </label>
  </el-form-item>
</template>

<script>
export default {
  name: 'TypedSelect',
  props: {
    options: Array,
    value: String,
    name: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    querySearch: Function,
  },
  watch: {
    inputVal(val) {
      this.$emit('input', val);
    },
  },
  data() {
    return {
      inputVal: this.value,
      focused: false,
    };
  },
  methods: {
    listenFocus($event) {
      this.focused = true;
    },
    listenBlur($event) {
      this.focused = false;
    },
    createFilter(queryString) {
      return link => (link.value.toLowerCase()
        .indexOf(queryString.toLowerCase()) === 0);
    },
    handleSelect(item) {

    },
    getOptions(queryString, cb) {
      const { options } = this;
      const results = queryString ? options.filter(this.createFilter(queryString)) : options;
      // call callback function to return suggestions
      cb(results);
    },
  },
};

</script>
<style lang="scss">
  li.el-select-dropdown__item {
    font-size: 0.875rem;
    &.selected {
      span {
        font-family: $fontBold;
      }

      &::before {
        border: none !important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .inline-input + label {
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 0.875rem;
    color: #909090;
    font-weight: 300;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
  }
  // new animated for selects
  ::v-deep .el-autocomplete {
    width: 100%;
  }

  ::v-deep .el-input.el-input__inner {
    padding-top: 15px;
    padding-left: 0;
  }

  ::v-deep .focused + label, .has-text + label {
    font-weight: 700 !important;
    font-size: 12px !important;
    transform: translate3d(0, -0.8rem, 0) !important;
  }

  ::v-deep .focused + label {
    color: $secondary;
  }

</style>
